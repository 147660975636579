import { AspectRatio, Box, Center, chakra, Divider, Heading, HStack, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { Fragment, useMemo } from "react"
import { Header, UserFacialAttendanceImage, UserTag } from "../../components"
import { Timer } from "../../components/common/Timer"
import { useAttendanceStatsQuery, useDemoFacialAttendanceUpdateSubscription } from "../../graphql"

export const DashboardPage: React.FC = () => {
	useDemoFacialAttendanceUpdateSubscription()

	// const [{ data: contractorsData }] = useContractorsQuery()

	const [{ data, fetching, error }] = useAttendanceStatsQuery()

	const { presentPercent, absentPercent } = useMemo(() => {
		if (!data?.attendanceStats) return { presentPercent: 0, absentPercent: 0 }

		const present = new Set(data.attendanceStats.attendances.map((s) => s.userId)).size
		const absent = data.attendanceStats.absent.length
		const total = data.attendanceStats.total.length

		return {
			presentPercent: Math.round((present / total) * 100),
			absentPercent: Math.round((absent / total) * 100),
		}
	}, [JSON.stringify(data?.attendanceStats)])

	// const contractorWiseData = useMemo(() => {
	// 	const _data: { contractor: DemoContractorFragment; total: number; present: number; absent: number }[] = []

	// 	if (!contractorsData?.contractors.length) return []

	// 	for (const contractor of contractorsData.contractors) {
	// 		const _present = data?.attendanceStats.ins.filter((att) => att.user?.contractorId === contractor._id) || []
	// 		const _absent = data?.attendanceStats.absent.filter((user) => user.contractorId === contractor._id) || []

	// 		_data.push({
	// 			contractor,
	// 			total: _present.length + _absent.length,
	// 			present: _present.length,
	// 			absent: _absent.length,
	// 		})
	// 	}

	// 	return _data
	// }, [JSON.stringify(data?.attendanceStats)])

	return (
		<VStack w="full" h="100vh" align="center" p="4" spacing={8}>
			<HStack w="full" position="relative" justify="space-between">
				<AspectRatio w="24" ratio={9 / 6}>
					<Box w="full" h="full" backgroundImage="/bigpluto_logo.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
				</AspectRatio>
				<Center position="absolute" top="0" left="0" right="0" bottom="0">
					<Header title="Real Time Facial Attendance Dashboard" />
				</Center>
				<Timer />
			</HStack>
			{fetching && !data?.attendanceStats ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading data</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.attendanceStats ? (
				<VStack flex={1} w="full" align="stretch" spacing="8">
					<VStack w="full">
						<HStack w="full" maxW="6xl" alignSelf="center" spacing="6">
							<VStack flex="1" align="stretch" p="1">
								<Box pos="relative" h="4">
									<Divider pos="absolute" top={0} left={0} bottom={0} right={0} borderColor="primary.500" borderWidth={4} rounded="full" />
								</Box>

								<Heading fontSize="xl" color="primary.400">
									Total
								</Heading>
								<Heading color="primary.400">
									{data.attendanceStats.total.length}{" "}
									<chakra.span fontSize="sm" fontWeight="normal" color="primary.400">
										users
									</chakra.span>
								</Heading>
							</VStack>
							<VStack flex="1" align="stretch" p="1">
								<Box pos="relative" h="4">
									<Divider pos="absolute" top={0} left={0} bottom={0} right={0} borderColor="success.500" borderWidth={4} opacity={0.2} rounded="full" />
									<Divider pos="absolute" top={0} left={0} bottom={0} width={`${presentPercent}%`} borderColor="success.500" borderWidth={4} rounded="full" />
								</Box>
								<Heading fontSize="xl" color="success.500">
									Present
								</Heading>
								<Heading color="success.500">
									{new Set(data.attendanceStats.attendances.map((s) => s.userId)).size}{" "}
									<chakra.span fontSize="sm" fontWeight="normal" color="success.500">
										users
									</chakra.span>
								</Heading>
							</VStack>
							<VStack flex="1" align="stretch" p="1">
								<Box pos="relative" h="4">
									<Divider pos="absolute" top={0} left={0} bottom={0} right={0} borderColor="error.500" borderWidth={4} opacity={0.2} rounded="full" />
									<Divider pos="absolute" top={0} left={0} bottom={0} width={`${absentPercent}%`} borderColor="error.500" borderWidth={4} rounded="full" />
								</Box>
								<Heading fontSize="xl" color="error.500">
									Absent
								</Heading>
								<Heading color="error.500">
									{data.attendanceStats.absent.length}{" "}
									<chakra.span fontSize="sm" fontWeight="normal" color="error.500">
										users
									</chakra.span>
								</Heading>
							</VStack>
							{/* <VStack flex="1" align="stretch" p="1">
							<Box pos="relative" h="4">
								<Divider pos="absolute" top={0} left={0} bottom={0} right={0} borderColor="warning.600" borderWidth={4} rounded="full" />
							</Box>

							<Heading fontSize="xl" color="warning.600">
								Unregistered
							</Heading>
							<Heading color="warning.600">
								{data.attendanceStats.unknowns.length}{" "}
								<chakra.span fontSize="sm" fontWeight="normal" color="warning.600">
									records
								</chakra.span>
							</Heading>
						</VStack> */}
						</HStack>

						{/* <HStack w="full" maxW="6xl" alignSelf="center" justify="center" spacing="6">
							{contractorWiseData.map(({ contractor, total, present }) => (
								<VStack key={contractor._id} flex={1} maxW="xs" align="stretch" p="1">
									<Box pos="relative" h="2">
										<Divider pos="absolute" top={0} left={0} bottom={0} right={0} borderColor="blackAlpha.600" borderWidth={2} opacity={0.2} rounded="full" />
										<Divider pos="absolute" top={0} left={0} bottom={0} width={`${(present / total) * 100}%`} borderColor="blackAlpha.800" borderWidth={2} rounded="full" />
									</Box>

									<Heading fontSize="md" color="blackAlpha.800">
										{contractor.name}
									</Heading>
									<Heading color="blackAlpha.800" fontSize="sm">
										{present}/{total}{" "}
										<chakra.span fontSize="xs" fontWeight="normal" color="blackAlpha.800">
											users
										</chakra.span>
									</Heading>
								</VStack>
							))}
						</HStack> */}
					</VStack>

					<VStack flex={1} w="full">
						<HStack w="full" alignItems="flex-start">
							<VStack flex={2} align="stretch" overflowY="auto" maxH="xl">
								<Heading fontSize="md" pos="sticky" top="0" bgColor="white" pb="2">
									Attendance
								</Heading>
								{data.attendanceStats.attendances.length ? (
									<Table flex={1} size="sm">
										<Thead>
											<Tr>
												<Th>User</Th>
												{/* <Th>Contractor</Th> */}
												<Th>IN</Th>
												<Th>OUT</Th>
											</Tr>
										</Thead>
										<Tbody>
											{data.attendanceStats.attendances.map((att) =>
												att.user ? (
													<Tr key={att._id}>
														<Td>
															<UserTag user={att.user} />
														</Td>
														{/* <Td>{att.user.contractor.name}</Td> */}
														<Td>
															<VStack w="fit-content" align="center">
																<UserFacialAttendanceImage image={att.isInImageUploaded ? att.inPicture?.original.url : att.inImage} isImageUploaded={!!att.isInImageUploaded} size="sm" />
																<Text>{format(new Date(att.inAt), "pp")}</Text>
															</VStack>
														</Td>
														{att.outAt ? (
															<Td>
																<VStack w="fit-content" align="center">
																	<UserFacialAttendanceImage image={att.isOutImageUploaded ? att.outPicture?.original.url : att.outImage} isImageUploaded={!!att.isOutImageUploaded} size="sm" />
																	<Text>{format(new Date(att.outAt), "pp")}</Text>
																</VStack>
															</Td>
														) : (
															<Td>-</Td>
														)}
													</Tr>
												) : (
													<Fragment key={att._id} />
												)
											)}
										</Tbody>
									</Table>
								) : (
									<Text fontSize="sm">No data available</Text>
								)}
							</VStack>
							<Box w="0.5" alignSelf="stretch" bgColor="gray.100" />
							{/* <VStack w="full" align="stretch" overflowY="auto" maxH="xl">
								<Heading fontSize="md" pos="sticky" top="0" bgColor="white" pb="2">
									Users Out
								</Heading>
								{data.attendanceStats.outs.length ? (
									<Table flex={1} size="sm">
										<Thead>
											<Tr>
												<Th>User</Th>
												<Th>Contractor</Th>
												<Th>Timestamp</Th>
											</Tr>
										</Thead>
										<Tbody>
											{data.attendanceStats.outs.map((att) =>
												att.user ? (
													<Tr key={att._id}>
														<Td>
															<UserTag user={att.user} image={att.isOutImageUploaded ? att.outPicture?.original.url : att.outImage} isImageUploaded={!!att.isOutImageUploaded} />
														</Td>
														<Td>{att.user.contractor.name}</Td>
														<Td>{format(new Date(att.outAt), "pp")}</Td>
													</Tr>
												) : (
													<Fragment key={att._id} />
												)
											)}
										</Tbody>
									</Table>
								) : (
									<Text fontSize="sm">No data available</Text>
								)}
							</VStack> */}
							<Box w="0.5" alignSelf="stretch" bgColor="gray.100" />
							<VStack flex={1} align="stretch" overflowY="auto" maxH="xl">
								<Heading fontSize="md" pos="sticky" top="0" bgColor="white" pb="2">
									Unregistered records
								</Heading>
								{data.attendanceStats.unknowns.length ? (
									<Table flex={1} size="sm">
										<Thead>
											<Tr>
												<Th>Image</Th>
												<Th>Timestamp</Th>
											</Tr>
										</Thead>
										<Tbody>
											{data.attendanceStats.unknowns.map((unknown) => (
												<Tr key={unknown._id}>
													<Td>
														{/* <Image h="32" w="32" src={`data:image/png;base64,${unknown.inImage}` || ""} alt="Unknown User Attendance Image" style={{ objectFit: "contain" }} bgColor="blackAlpha.200" /> */}
														<UserFacialAttendanceImage image={unknown.isInImageUploaded ? unknown.inPicture?.original.url : unknown.inImage} isImageUploaded={!!unknown.isInImageUploaded} />
													</Td>
													<Td>{format(new Date(unknown.inAt), "PPpp")}</Td>
												</Tr>
											))}
										</Tbody>
									</Table>
								) : (
									<Text fontSize="sm">No data available</Text>
								)}
							</VStack>
						</HStack>
					</VStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any data.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
