import React from "react"
import { Route, Routes } from "react-router-dom"
import { DashboardPage } from "../pages/dashboard/Dashboard.page"

export const RootRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<DashboardPage />} />
		</Routes>
	)
}
