import { cacheExchange as cE } from "@urql/exchange-graphcache"
import { betterQueryUpdate } from "../../utils"
import { AttendanceStatsDocument, AttendanceStatsQuery, DemoFacialAttendanceUpdateSubscription } from "../generated"

import schema from "../generated/schema.json"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		DemoUserAttendanceStatsResponse: () => null,
		Picture: () => null,
		PictureObject: () => null,
	},
	resolvers: {
		Query: {},
	},
	updates: {
		Subscription: {
			demoFacialAttendanceUpdate: (_result: DemoFacialAttendanceUpdateSubscription, _, cache) => {
				betterQueryUpdate<DemoFacialAttendanceUpdateSubscription, AttendanceStatsQuery>(
					cache,
					{
						query: AttendanceStatsDocument,
					},
					_result,
					(result, query) => {
						if (result?.demoFacialAttendanceUpdate && query?.attendanceStats) {
							if (result.demoFacialAttendanceUpdate.isUnknown || !result.demoFacialAttendanceUpdate.userId) {
								const unknownIndex = query.attendanceStats.unknowns.findIndex((u) => u._id === result.demoFacialAttendanceUpdate?._id)

								if (query.attendanceStats.unknowns[unknownIndex]) {
									const _unknowns = [...query.attendanceStats.unknowns]

									_unknowns[unknownIndex] = result.demoFacialAttendanceUpdate

									return {
										...query,
										attendanceStats: {
											...query.attendanceStats,
											unknowns: _unknowns,
										},
									}
								}

								return {
									...query,
									attendanceStats: {
										...query.attendanceStats,
										unknowns: [result.demoFacialAttendanceUpdate, ...query.attendanceStats.unknowns],
									},
								}
							}

							const isInTotal = query.attendanceStats.total.find((u) => u === result.demoFacialAttendanceUpdate?.userId)

							if (!isInTotal) {
								return {
									...query,
									attendanceStats: {
										...query.attendanceStats,
										total: [...query.attendanceStats.total, result.demoFacialAttendanceUpdate?.userId],
										attendances: [result.demoFacialAttendanceUpdate, ...query.attendanceStats.attendances],
										absent: query.attendanceStats.absent.filter((u) => u._id !== result.demoFacialAttendanceUpdate?.userId),
									},
								}
							}

							const attendanceIndex = query.attendanceStats.attendances.findIndex((u) => u._id === result.demoFacialAttendanceUpdate?._id)

							if (query.attendanceStats.attendances[attendanceIndex]) {
								const _attendances = [...query.attendanceStats.attendances]

								_attendances[attendanceIndex] = result.demoFacialAttendanceUpdate

								return {
									...query,
									attendanceStats: {
										...query.attendanceStats,
										attendances: _attendances,
										absent: query.attendanceStats.absent.filter((u) => u._id !== result.demoFacialAttendanceUpdate?.userId),
									},
								}
							}

							return {
								...query,
								attendanceStats: {
									...query.attendanceStats,
									absent: query.attendanceStats.absent.filter((u) => u._id !== result.demoFacialAttendanceUpdate?.userId),
									attendances: [result.demoFacialAttendanceUpdate, ...query.attendanceStats.attendances],
								},
							}
						}
						return query
					}
				)
			},
		},
		Mutation: {},
	},
})
