import { Badge } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"

// eslint-disable-next-line react/display-name
export const Timer: React.FC = React.memo(() => {
	const [currentTime, setCurrentTime] = useState(new Date())

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date())
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		const hours = currentTime.getHours()
		const minutes = currentTime.getMinutes()
		const seconds = currentTime.getSeconds()

		// at midnight
		if (hours === 0 && minutes === 0 && seconds === 1) {
			window.location.reload()
		}
	}, [currentTime.getTime()])

	return (
		<Badge fontSize="xl" w="xs" textAlign="center">
			{format(new Date(currentTime), "PPpp")}
		</Badge>
	)
})
