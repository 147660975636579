import { Container } from "@chakra-ui/react"
import React from "react"
import { RootRouter } from "./router/Root.router"

export const App: React.FC = () => {
	return (
		<Container maxW="2560px">
			<RootRouter />
		</Container>
	)
}
