import { Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, VStack } from "@chakra-ui/react"
import React, { Fragment, useState } from "react"

export type UserFacialAttendanceImageProps = {
	image?: string | undefined | null
	isImageUploaded?: boolean
	size?: "md" | "sm" | "lg"
}

export const UserFacialAttendanceImage: React.FC<UserFacialAttendanceImageProps> = ({ image, isImageUploaded, size = "lg" }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [isImageAvailable, setIsImageAvailable] = useState<boolean>(!!image)

	const imageSrc = isImageUploaded ? image : `data:image/png;base64,${image}`

	if (!image) {
		return <></>
	}

	return (
		<Fragment>
			<Image
				h={size === "sm" ? "12" : size === "md" ? "24" : "32"}
				w={size === "sm" ? "12" : size === "md" ? "24" : "32"}
				style={{ objectFit: "contain" }}
				bgColor="blackAlpha.200"
				onClick={isImageAvailable ? onOpen : onClose}
				onError={() => setIsImageAvailable(false)}
				src={imageSrc || undefined}
				alt="User In Attendance Image"
				fallbackSrc="/no-image-available.png"
				cursor="pointer"
			/>

			<Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody as={VStack} w="full">
						<Image src={imageSrc || undefined} alt="User Attendance Image" />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Fragment>
	)
}
