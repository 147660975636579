import { Heading, HStack, VStack } from "@chakra-ui/react"
import React from "react"
import { DemoUserFragment } from "../../graphql"

export type UserTagProps = {
	user: DemoUserFragment
	size?: "md" | "sm"
}

export const UserTag: React.FC<UserTagProps> = ({ user, size = "md" }) => {
	return (
		<HStack>
			<VStack align="flex-start" spacing={0}>
				<Heading fontSize={size} color="grayscale.title">
					{user.name}
				</Heading>
				<Heading fontSize={size === "md" ? "sm" : "xs"} color="grayscale.label">
					@{user.username}
				</Heading>
			</VStack>
		</HStack>
	)
}
